// 商品管理中的网络请求

import { request } from './request'

// 商品分类---------------------------

// 获取商品分类的列表
export function getCategoriesListRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/list',
    method: 'get',
    params
  })
}

// 获取商品分类的列表
export function getWholeCategoriesListRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/check/category',
    method: 'get',
    params
  })
}

// 获取商品分类的列表
export function getCategoryChildrenRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/children',
    method: 'get',
    params
  })
}

// 添加分类
export function addCategoryRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/add',
    method: 'post',
    data: { ...params }
  })
}

// 根据 id 查询分类
export function getCategory (categoryId) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/category/${categoryId}`
  })
}

// 根据 id 编辑提交分类
export function updateCategory (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/category/${params.categoryId}`,
    method: 'put',
    data: {
      name: params.name,
      logo: params.logo,
      parent_id: params.parent_id
    }
  })
}

export function moveCategory (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/move',
    method: 'post',
    data: { ...params }
  })
}

export function updateCategoryStatus (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/category/${params.categoryId}/status`,
    method: 'put',
    data: {
      status: params.status,
      categoryId: params.categoryId
    }
  })
}

// 根据 id 编辑提交分类
export function deleteCategory (categoryId) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/category/${categoryId}`,
    method: 'delete'
  })
}

// 更改分类顺序
export function SortCategoryRequest (data) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/sort',
    method: 'post',
    data
  })
}

// 分类属性管理----------------------------

// 添加分类属性
export function addAttributeRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/category/${params.categoryId}/attribute`,
    method: 'post',
    data: {
      name: params.name,
      sel: params.sel
    }
  })
}

// 获取属性列表
export function listAttributeRequest (categoryId, sel) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/list/attribute',
    params: {
      sel: sel,
      categoryId: categoryId
    }
  })
}

// 获取属性信息
export function GetAttributeRequest (attrId) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/category/attr/${attrId}`
  })
}

// 获取属性列表
export function UpdateAttrValRequest (operate, attrId, attrVal) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/update/attr/val',
    method: 'post',
    data: {
      operate: operate,
      attrId: attrId,
      attrVal: attrVal
    }
  })
}

// 更新属性
export function UpdateAttrRequest (data) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/update/attr',
    method: 'put',
    data
  })
}

// 删除属性
export function RemoveAttrRequest (id) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/category/attr/${id}`,
    method: 'delete'
  })
}

// 排序属性
export function SortAttrRequest (data) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/attr/sort/key',
    method: 'post',
    data
  })
}

// 商品管理--------------------------------------

// 获取商品列表数据
export function getGoodsList (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/list',
    params
  })
}

// 获取商品详情数据
export function getGoodsDetail (id) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/detail',
    params: {
      goodsId: id,
      isAdmin: 1
    },
    method: 'get'
  })
}

// 根据 ID 删除商品
export function removeGoods (id) {
  return request({
    url: `/goods/${id}`,
    method: 'delete'
  })
}

// 添加商品
export function addGoodsRequest (data) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/add',
    method: 'post',
    data
  })
}

// 更新商品
export function updateGoodsRequest (id, data) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/goods/${id}`,
    method: 'put',
    data
  })
}

// 获取列表
export function ListSaleTermRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/sale-term/list',
    method: 'get',
    params
  })
}

// 添加条款
export function AddSaleTermRequest (addInfo) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/sale-term/add',
    method: 'post',
    data: { ...addInfo }
  })
}

// 获取条款
export function GetSaleTermRequest (termId) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/sale-term/${termId}`,
    method: 'get'
  })
}

// 修改条款
export function UpdateSaleTermRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/sale-term/${info.id}`,
    method: 'put',
    data: {
      title: info.title,
      desc: info.desc
    }
  })
}

// 删除条款
export function DelSaleTermRequest (termId) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/sale-term/${termId}`,
    method: 'delete'
  })
}

// 修改商品店铺
export function UpdateGoodsShop (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/update/shop',
    method: 'post',
    data: { ...params }
  })
}

// 计算商品价格
export function CalcGoodsPrice (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/calc/price',
    params
  })
}

export function CategoryGoodsCount (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/category/goods/count',
    params
  })
}

// 修改参与夺宝状态
export function UpdateGoodsStatus (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/status',
    method: 'post',
    data: { ...params }
  })
}

// 修改参与夺宝状态
export function UpdateGoodsAdStatus (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/update/ad',
    method: 'post',
    data: { ...params }
  })
}

// 修改参与夺宝状态
export function UpdateHuntStatus (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/update/hunt',
    method: 'post',
    data: { ...params }
  })
}

export function updateAdGoodsRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/ad-goods/update',
    method: 'post',
    data: { ...info }
  })
}

export function updateAdGoodsStatusRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/ad-goods/update/status',
    method: 'post',
    data: { ...params }
  })
}

export function getAdGoodsListRequest (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/ad-goods/list',
    method: 'get',
    params
  })
}

export function getAdGoodsDetailRequest (id) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/ad-goods/detail',
    params: {
      id: id
    },
    method: 'get'
  })
}

export function getExpressList (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/express/list',
    method: 'get',
    params
  })
}

export function delExpressRequest (id) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/express/${id}`,
    method: 'delete'
  })
}

export function addExpressRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/express/add',
    method: 'post',
    data: { ...info }
  })
}

export function updateExpressRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/express/update',
    method: 'post',
    data: { ...info }
  })
}

export function getExpressDetailRequest (id) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/express/detail',
    params: {
      id: id
    },
    method: 'get'
  })
}

export function listGoodsCommentKnowledge (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/comment/knowledge/list',
    method: 'get',
    params
  })
}

export function delGoodsCommentKnowledgeRequest (id) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: `/goods/comment/knowledge/${id}`,
    method: 'delete'
  })
}

export function addGoodsCommentKnowledgeRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/comment/knowledge/add',
    method: 'post',
    data: { ...info }
  })
}

export function updateGoodsCommentKnowledgeRequest (info) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/comment/knowledge/update',
    method: 'post',
    data: { ...info }
  })
}

export function listGoodsComment (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/comment/list',
    method: 'get',
    params
  })
}

export function manualGoodsComment (params) {
  return request({
    baseUrl: window.g.GOODS_URL,
    url: '/goods/manual/comment',
    method: 'post',
    data: { ...params }
  })
}
