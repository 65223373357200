<template>
  <div>
    <el-button type="primary" size="mini" :disabled="isBtnDisabled" @click="addDialogVisible = true">
      <slot name="title">添加参数</slot>
    </el-button>

    <!-- 添加动态参数/静态属性的对话框 -->
    <el-dialog
      :title="'添加' + titleText"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose">
      <el-form :model="addForm" :rules="formRules" ref="addFormRef" label-width="100px">
        <el-form-item :label="titleText" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addAttribute">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { addAttributeRequest, CategoryGoodsCount } from '../../../network/goods'

export default {
  name: 'AttributeAdd',
  props: {
    titleText: {
      type: String,
      default: ''
    },
    isBtnDisabled: {
      type: Boolean,
      default: false
    },
    categoryId: {
      type: Number,
      default: 0
    },
    activeName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      addDialogVisible: false,
      addForm: { // 添加表单的规则
        name: ''
      },
      formRules: {
        name: [
          { required: true, message: '请输入属性名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 监听添加对话框的关闭
    addDialogClose () {
      this.$refs.addFormRef.resetFields()
    },

    // 添加属性
    addAttribute () {
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return
        console.log('cat:', this.categoryId, ';attr:', this.addForm.name, ';active:', this.activeName)
        if (this.activeName === 'many') {
          CategoryGoodsCount({
            categoryId: this.categoryId,
            supplierId: 0
          }).then(res => {
            if (res.status !== 200) {
              this.$message.warning('请求服务端出错')
              return
            }
            console.log('count data:', res)
            if (res.data.data.total > 0) {
              this.$message.warning('该分类已有规格下定义了商品，不能添加新规格, 请添加新分类或现有规格添加属性值')
              return
            }
            addAttributeRequest({
              categoryId: this.categoryId,
              name: this.addForm.name,
              sel: this.activeName
            }).then(res => {
              if (res.status !== 200) {
                return this.alertMessage('添加属性失败', 'error')
              }
              this.$emit('attribute-list')
              this.addDialogVisible = false
              this.alertMessage('添加属性成功', 'success')
            })
          })
        } else {
          addAttributeRequest({
            categoryId: this.categoryId,
            name: this.addForm.name,
            sel: this.activeName
          }).then(res => {
            if (res.status !== 200) {
              return this.alertMessage('添加属性失败', 'error')
            }
            this.$emit('attribute-list')
            this.addDialogVisible = false
            this.alertMessage('添加属性成功', 'success')
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
