<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>分类属性</template>
    </breadcrumb-nav>
    <el-card style="margin: 10px 0px">
      <el-alert style="margin-bottom: 10px;" type="warning" title="注意：设置属性前请先选择分类" :closable="false" show-icon></el-alert>
      <cate-select @categoryChange="categoryChange"></cate-select>
    </el-card>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="规格属性" name="many">
          <!-- 标签页组件 -->
          <attribute-tab-pane
            :table-data="manyTableData"
            :title-text="titleText"
            :is-btn-disabled="isBtnDisabled"
            :category-id="categoryId"
            :active-name="activeName"
            @attribute-list="updateAttributeList"/>
        </el-tab-pane>

        <el-tab-pane label="参数属性" name="only">
          <attribute-tab-pane
            :table-data="onlyTableData"
            :title-text="titleText"
            :is-btn-disabled="isBtnDisabled"
            :category-id="categoryId"
            :active-name="activeName"
            @attribute-list="updateAttributeList"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>

import BreadcrumbNav from '../common/BreadcrumbNav'
import CateSelect from '../common/CateSelect'
import AttributeTabPane from './attributeChildComponents/AttributeTabPane'
import { listAttributeRequest } from '../../network/goods'

export default {
  name: 'Attribute',
  components: {
    BreadcrumbNav,
    CateSelect,
    AttributeTabPane
  },
  computed: {
    isBtnDisabled () {
      return this.categoryId === 0
    },
    titleText () {
      return this.activeName === 'many' ? '规格属性' : '参数属性'
    }
  },
  data () {
    return {
      categoryId: 0,
      activeName: 'many',
      manyTableData: [], // 规格属性数据
      onlyTableData: [] // 展示属性数据
    }
  },
  methods: {
    handleTabClick () {
      this.getCategoryAttribute()
    },
    // 分类改变，获取属性列表
    categoryChange (categoryId) {
      this.categoryId = categoryId
      this.getCategoryAttribute()
    },
    getCategoryAttribute () {
      if (this.categoryId === 0) {
        return
      }
      listAttributeRequest(this.categoryId, this.activeName).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取参数失败', 'error')
        }
        console.log('--------list cate:', this.categoryId, ';attr result:', result.data)
        result.data.list.forEach(item => {
          // 控制文本框的显示与隐藏
          item.inputVisible = false
          item.inputValue = ''
        })
        if (this.activeName === 'many') {
          this.manyTableData = result.data.list
        } else {
          this.onlyTableData = result.data.list
        }
      })
    },
    // 监听子组件触发的事件
    updateAttributeList () {
      this.getCategoryAttribute()
    }
  }
}
</script>

<style scoped>

</style>
