<template>
  <div>
    <!-- 添加参数/属性组件 -->
    <attribute-add
      :title-text="titleText"
      :is-btn-disabled="isBtnDisabled"
      :category-id="categoryId"
      :active-name="activeName"
      @attribute-list="updateAttributeList">
      <template v-slot:title>添加{{ titleText }}</template>
    </attribute-add>

    <el-table :data="tableData" border stripe style="margin-top: 10px;">
      <el-table-column width="30" type="index" label="#"></el-table-column>
      <el-table-column width="120"  label="属性名称" prop="name"></el-table-column>
      <el-table-column label="属性值">
        <template slot-scope="scope">
          <!-- 循环渲染tag标签 -->
          <el-tag style="margin-top:5px;" v-for="(item,i) in scope.row.attrVals" :key="i" closable @close="handleClose(i,scope.row)">{{item}}</el-tag>
          <!-- 输入的文本框 -->
          <el-input
            class="input-new-tag"
            v-if="scope.row.inputVisible"
            v-model="scope.row.inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm(scope.row)"
            @blur="handleInputConfirm(scope.row)"
          ></el-input>
          <!-- 添加按鈕 -->
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput(scope.row)"
          >+ 添加属性</el-button>
        </template>
      </el-table-column>
      <el-table-column width="180"  label="操作">
        <template slot-scope="scope">
          <!-- 编辑按钮的组件 -->
          <attribute-edit
            :title-text="titleText"
            :attr_id="scope.row.id"
            :category-id="categoryId"
            :active-name="activeName"
            @attribute-list="updateAttributeList"/>
          <!-- 删除按钮的组件 -->
          <attribute-remove :attr_id="scope.row.id" :category-id="categoryId" :active-name="activeName" @attribute-list="updateAttributeList"/>
          <el-button icon="el-icon-top" style="margin-left: 0;margin-top:10px;" type="success" size="mini" @click="upindex(scope.$index,scope.row)">上移</el-button>
          <el-button icon="el-icon-bottom" type="success" size="mini" @click="downindex(scope.$index,scope.row)">下移</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import AttributeAdd from './AttributeAdd'
import AttributeEdit from './AttributeEdit'
import AttributeRemove from './AttributeRemove'
import { SortAttrRequest, UpdateAttrValRequest } from '../../../network/goods'

export default {
  name: 'AttributeTabPane',
  components: {
    AttributeAdd,
    AttributeEdit,
    AttributeRemove
  },
  props: {
    titleText: {
      type: String,
      default: ''
    },
    isBtnDisabled: {
      type: Boolean,
      default: false
    },
    categoryId: {
      type: Number,
      default: 0
    },
    activeName: {
      type: String,
      default: ''
    },
    tableData: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    updateAttributeList () {
      this.$emit('attribute-list')
    },
    // 文本框失去焦点或按下enter都会触发
    handleInputConfirm (row) {
      if (row.inputValue.trim() === '') {
        row.inputValue = ''
        row.inputVisible = false
        return
      }
      /* if (row.attrVals.length >= 5) {
        this.alertMessage('一个属性最多添加5个属性值', 'info')
        row.inputValue = ''
        row.inputVisible = false
        return
      } */
      if (this.hasSameAttrVal(row)) {
        this.alertMessage('已经存在相同的属性值', 'info')
        row.inputValue = ''
        row.inputVisible = false
        return
      }
      const addAttrVal = row.inputValue
      // 输入了内容，进行后续处理
      row.attrVals.push(row.inputValue)
      row.inputValue = ''
      row.inputVisible = false
      // 将新加入的标签通过http请求存入数据库中
      this.updateAttrVal('add', row.id, addAttrVal)
    },
    hasSameAttrVal (row) {
      // 属性值不能重复,这里也可以用some
      const result = row.attrVals.every(
        (item) => item !== row.inputValue
      )
      return !result
    },
    // 点击按钮展示文本输入框
    showInput (row) {
      row.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    // 监听tag标签的删除事件
    handleClose (i, row) {
      const removes = row.attrVals.splice(i, 1)
      if (removes.length > 0) {
        console.log('------remove tag:', removes[0])
        this.updateAttrVal('del', row.id, removes[0])
      }
    },
    updateAttrVal (operate, attrId, attrVal) {
      UpdateAttrValRequest(operate, attrId, attrVal).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('更新属性值失败', 'error')
        }
        let alertMsg = '更新属性值成功'
        switch (operate) {
          case 'add':
            alertMsg = '添加属性值成功'
            break
          case 'del':
            alertMsg = '删除属性值成功'
            break
          default:
            break
        }
        this.alertMessage(alertMsg, 'success')
      })
    },
    upindex (index, row) {
      if (index > 0) {
        const curData = this.tableData[index]
        const upData = this.tableData[index - 1]
        console.log('cur:', this.tableData[index].id, ',up:', upData.id)
        const sortArr = []
        sortArr.push({ id: curData.id, sortNum: upData.sortNum })
        sortArr.push({ id: upData.id, sortNum: curData.sortNum })
        SortAttrRequest({ list: sortArr }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('上移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = upData.sortNum
          upData.sortNum = curSortNum
          this.tableData.splice(index - 1, 1)
          this.tableData.splice(index, 0, upData)
          console.log('after up:', this.tableData)
        })
      } else {
        this.$message.warning('已经是第一条了！')
        return false
      }
    },
    downindex (index, row) {
      if (index + 1 === this.tableData.length) {
        this.$message.warning('已经是最后一条了！')
        return false
      } else {
        const downData = this.tableData[index + 1]
        const curData = this.tableData[index]
        const sortArr = []
        sortArr.push({ id: curData.id, sortNum: downData.sortNum })
        sortArr.push({ id: downData.id, sortNum: curData.sortNum })
        SortAttrRequest({ list: sortArr }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('下移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = downData.sortNum
          downData.sortNum = curSortNum
          this.tableData.splice(index + 1, 1)
          this.tableData.splice(index, 0, downData)
          console.log('after down:', this.tableData)
        })
      }
    }
  }
}
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
