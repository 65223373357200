<template>
  <el-form :inline="true" class="demo-form-inline" :disabled="forbidden">
    <el-form-item label="商品分类">
      <el-cascader
        ref="category"
        :props="props"
        @change="handleChange"
        v-model="value"
        clearable="clearable"
      ></el-cascader>
    </el-form-item>
  </el-form>
</template>

<script>
import { getCategoriesListRequest } from '../../network/goods'
export default {
  name: 'CateSelect',
  props: {
    forbidden: {
      type: Boolean,
      default () {
        return false
      }
    },
    excludeId: {
      type: Number,
      default () {
        return 0
      }
    },
    clearable: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  created () {
    // 获取分类的数据
    // this.getCategoryList()
    console.log('selected category excludeId:', this.excludeId)
    this.value = []
  },
  data () {
    return {
      value: [], // 多级联动的值 => 会是一个数组
      props: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level } = node
          const nodes = []
          const parentId = level === 0 ? 0 : node.value
          getCategoriesListRequest({ page: 0, size: 0, parentId: parentId, excludeId: this.excludeId }).then(res => {
            if (res.status !== 200) {
              return this.alertMessage('获取分类列表失败', 'error')
            }
            const result = res.data.data.list
            // 节点数组
            result.map((item) => {
              const obj = {
                value: item.categoryId,
                label: item.name,
                leaf: parentId !== 0
              }
              nodes.push(obj)
            })
            resolve(nodes)
          })
        }
      }
    }
  },
  methods: {
    handleChange (value) {
      console.log('current selected:', value)
      if (value.length === 2) {
        this.$emit('categoryChange', parseInt(value[1]))
      } else if (value.length === 0) {
        this.$emit('categoryChange', 0)
      }
    },
    setValue (value) {
      this.$refs.category.inputValue = value
    }
  }
}
</script>

<style>
.el-cascader-panel {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
</style>
