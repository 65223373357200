<template>
  <el-button type="danger" size="mini" icon="el-icon-delete" @click="removeAttribute(attr_id)">删除</el-button>
</template>

<script>

import { CategoryGoodsCount, RemoveAttrRequest } from '../../../network/goods'

export default {
  name: 'AttributeRemove',
  props: {
    attr_id: {
      type: Number,
      default: 0
    },
    categoryId: {
      type: Number,
      default: 0
    },
    activeName: {
      type: String,
      default: ''
    }
  },
  methods: {
    // 删除参数
    removeAttribute (attrId) {
      console.log('remove attr cate:', this.categoryId, ',activeName:', this.activeName)
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.activeName === 'many') {
          CategoryGoodsCount({
            categoryId: this.categoryId,
            supplierId: 0
          }).then(res => {
            if (res.status !== 200) {
              this.$message.warning('请求服务端出错')
              return
            }
            console.log('count data:', res)
            if (res.data.data.total > 0) {
              this.$message.warning('该规格下定义了商品，不能删除')
              return
            }
            RemoveAttrRequest(attrId).then(res => {
              if (res.status !== 200) {
                return this.alertMessage('删除失败', 'error')
              }
              this.alertMessage('删除成功', 'success')
              this.$emit('attribute-list')
            })
          })
        } else {
          RemoveAttrRequest(attrId).then(res => {
            if (res.status !== 200) {
              return this.alertMessage('删除失败', 'error')
            }
            this.alertMessage('删除成功', 'success')
            this.$emit('attribute-list')
          })
        }
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    }
  }
}
</script>

<style scoped>

</style>
